import React from "react"

import Layout from "../components/layout"
import Landing from "../components/productSections/grievanceLanding"
import About from "../components/productSections/grievanceAbout"

import Features from "../components/productSections/grievanceFeatures"
import Brocher from "../components/productSections/grievanceBrocher"

const grievancePage = () => (
    <Layout>
       <Landing />
         <About />
         <Features />
       <Brocher/>
    </Layout>
)

export default grievancePage