import React from "react"
import vector10 from "./../../assets/images/vectors/vector10.svg"
import icon11 from "./../../assets/images/icons/icon11.svg"
import icon12 from "./../../assets/images/icons/icon12.svg"
import icon13 from "./../../assets/images/icons/icon13.svg"
import icon14 from "./../../assets/images/icons/icon14.svg"
import icon15 from "./../../assets/images/icons/icon15.svg"
import icon16 from "./../../assets/images/icons/icon16.svg"
import icon17 from "./../../assets/images/icons/icon17.svg"
import icon18 from "./../../assets/images/icons/icon18.svg"
import icon19 from "./../../assets/images/icons/icon19.svg"



export default function GrievanceFeatures() {
  return (
    <React.Fragment>
      <div id="grievance-features" className="grievance-features">
        <div className="grievance-features__container">


          <img className="grievance-features__containerImg"
              src={ vector10 }
              alt="grievance-Feature Image"
              
            />
       


        </div>
        <div className="grievance-features__container1">
          <div className="grievance-features__left">
              <div className="grievance-features__list ">
                <div className="grievance-features__img">
                  <img src={icon11} alt="image" />
                </div>
                <div className="grievance-features__contents">
                  Digital signature capability allowing Aadhar authenticated digital signatures
              </div>

              </div>
              <div className="grievance-features__list ">
                <div className="grievance-features__img">
                  <img src={icon12} alt="image" />
                </div>
                <div className="grievance-features__contents">
                  Analytics to show you the Return on Investment on the Webnyay platform
              </div>

              </div>
              <div className="grievance-features__list ">
                <div className="grievance-features__img">
                  <img src={icon13} alt="image" />
                </div>
                <div className="grievance-features__contents">
                  Transparency – audit trail of all user actions maintained
              </div>

              </div>
              <div className="grievance-features__list ">
                <div className="grievance-features__img">
                  <img src={icon14} alt="image" />
                </div>
                <div className="grievance-features__contents">
                  All data is securely stored on cloud in India
              </div>

              </div>



          </div>
          <div className="grievance-features__right">

             <div className="grievance-features__list ">
                <div className="grievance-features__img">
                  <img src={icon15} alt="image" />
                </div>
                <div className="grievance-features__contents">
                Platform is completely secure
                </div>
              </div>


              <div className="grievance-features__list ">
                <div className="grievance-features__img">
                  <img src={icon16} alt="image" />
                </div>
                <div className="grievance-features__contents">
                Available to use on a white-label basis with on-cloud deployment 
                </div>
              </div>



               <div className="grievance-features__list ">
                <div className="grievance-features__img">
                  <img src={ icon17 } alt="image" />
                </div>
                <div className="grievance-features__contents">
                Reminders to respond within the statutory deadline
                </div>
              </div>


              <div className="grievance-features__list ">
                <div className="grievance-features__img">
                  <img src={icon18} alt="image" />
                </div>
                <div className="grievance-features__contents">
                Private cloud storage for documents
                </div>
              </div>


              <div className="grievance-features__list ">
                <div className="grievance-features__img">
                  <img src={ icon19 } alt="image" />
                </div>
                <div className="grievance-features__contents">
                Calendar that allows you to track all dates and deadlines
                </div>
              </div> 






          </div>
        </div>
    
    
      </div>
    </React.Fragment>
  )
}
