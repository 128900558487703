import React from "react"

export default function GrievanceBrocher() {
  return  (
    <div id="grievance-brocher" className="grievance-brocher">
      <div className="grievance-brocher__container">
        <h4
          className="product-landing__content" >
          Browse through our Grievance Redressal brochure to understand better
        </h4>

      <div className="grievance-brocher__button-container">
       
        <a href="https://forms.office.com/r/NVAuVwnVjW" target="__blank">
          <button
            className="btn btn--contained  btn--large btn--rounded "
            data-sal="slide-up"
            data-sal-delay="200"
            data-sal-duration="700"
          >
            Download Brochure
          </button>
        </a>
      </div>
    </div>
    </div>
  )
}

