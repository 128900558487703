import React from "react"

export default () => {
  return (
    <div id="grievance-about" className="grievance-about">
      <div className="grievance-about__container">
        <h4
          className="grievance-about__content">
          Webnyay is India's leading online grievance redressal platform.
        </h4>
        <div className="u-margin-top-small"></div>

        <h4
          className="grievance-about__content">
          We provide an online multi-lingual platform for businesses and Government authorities to manage their grievance redressal flow. The platform is customisable and can be customised to suit your requirements.
        </h4>
        <div className="u-margin-top-small"></div>

        <h4
          className="grievance-about__content">
          Our grievance redressal platform is being used to resolve grievances by businesses in the news, broadcasting, media and entertainment, banking, financial services, insurance and commerce sectors.
        </h4>

        <div className="u-margin-top-small"></div>

        <h4 className="grievance-about__content">
          Our platform is being used by social media intermediaries, digital publications, online content curation companies, OTT providers and others to set up a multi-tier grievance redressal mechanism as per the Information Technology (Intermediary Guidelines and Digital Media Ethics Code) Rules, 2021 issued by the Government of India in February 2021.
        </h4>

        <div className="u-margin-top-small"></div>

        <h4 className="grievance-about__content" >
          Our platform is being used by banking and financial institutions (including NBFCs, micro-lending institutions and digital fintech lenders) to set up a grievance redressal mechanism as per the requirements of the Reserve Bank of India.
        </h4>

        <div className="u-margin-top-small"></div>

        <h4 className="grievance-about__content" >
          The platform is secure and serves as a one-stop-shop to register, manage, respond to and resolve grievances. The platform is available in five Indian languages and has an intelligent document automation technology, Webnyay's proprietary technology with Artificial Intelligence and machine learning tools. We automate the grievance redressal processes and make it efficient and easy for all stakeholders.
        </h4>

        <div className="u-margin-top-small"></div>

        <h4 className="grievance-about__content"  >
          The platform also a video conferencing software and a messaging and communications network for the parties to communicate. All proceedings can be recorded and replayed later.
        </h4>

        <div className="u-margin-top-small"></div>

        <h4 className="grievance-about__content" >

          The platform can also generate compliance reports that can be submitted to the regulators and Government departments as well as published in the usual course of business.
        </h4>

        <h4 className="grievance-about__content">
          For further details as well as a demo of the platform, please contact us at admin@webnyay.in.
        </h4>


      </div>
      <div className="u-margin-top-medium"></div>
    </div>
  )
}
