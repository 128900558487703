import React from "react"
import vector6 from "./../../assets/images/vectors/Vector6.svg"

export default function GrievanceLanding() {
  return (
    <React.Fragment>
      <div id="grievance-landing" className="grievance-landing">
        <div className="buffer only-short-height"></div>

        <div className="grievance-landing__container">
          <div className="grievance-landing__left">
            
            <h4
              className="grievance-landing__content">
                Grievance Redressal under the Information Technology (Intermediary Guidelines and Digital Media Ethics Code) Rules, 2021​
            </h4>
            <div className="u-margin-top-small"></div>
            <h3 className="grievance-landing__caption">Resolve Grievances quickly and efficiently</h3>
          </div>
          <div className="grievance-landing__grow"></div>
          <div className="grievance-landing__right">
            <img
              src={ vector6 }
              alt="grievance-landing page main image"
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
